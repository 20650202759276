
import { defineComponent } from 'vue'
import ChatsSettingsPage from '@/components/pages/chats/settings/ChatsSettingsPage.vue'
import DetailsHero from '@/components/shared/templates/details/DetailsHero.vue'
import InfoCards from '@/components/shared/templates/InfoCards.vue'
import ChatWhatsAppDetailedGeneral from '@/components/pages/chats/settings/whatsApp/detailed/ChatWhatsAppDetailedGeneral.vue'
import ChatWhatsAppDetailedLinkedNumbers from '@/components/pages/chats/settings/whatsApp/detailed/ChatWhatsAppDetailedLinkedNumbers.vue'
import ChatWhatsAppDetailedTemplates from '@/components/pages/chats/settings/whatsApp/detailed/ChatWhatsAppDetailedTemplates.vue'
import ChatWhatsAppDetailedTerms from '@/components/pages/chats/settings/whatsApp/detailed/ChatWhatsAppDetailedTerms.vue'
import ChatWhatsAppDetailedDisconect from '@/components/pages/chats/settings/whatsApp/detailed/ChatWhatsAppDetailedDisconect.vue'

export default defineComponent({
  components: {
    ChatsSettingsPage,
    DetailsHero,
    InfoCards,
    ChatWhatsAppDetailedGeneral,
    ChatWhatsAppDetailedLinkedNumbers,
    ChatWhatsAppDetailedTemplates,
    ChatWhatsAppDetailedTerms,
    ChatWhatsAppDetailedDisconect,
  },
  setup() {
    const breadcrumbs = [
      { label: 'Messenger', name: 'base.chats.main' },
      { label: 'Messenger settings', name: 'base.chats.settings' },
      { label: 'WhatsApp', name: 'base.chats.settings.whatsApp' },
      { label: 'Nestle USA' },
    ]

    return {
      breadcrumbs,
    }
  },
})
