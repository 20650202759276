
import { defineComponent } from 'vue'
import TmTable from '@/components/shared/table/TmTable.vue'
import TmColoredUl from '@/components/shared/templates/TmColoredUl.vue'
import TmFlag from '@/components/shared/TmFlag.vue'
import NumbersDropdown from '@/components/pages/chats/settings/whatsApp/detailed/NumbersDropdown.vue'
import TmBadge from '@/components/shared/TmBadge.vue'
import TmStatus from '@/components/shared/TmStatus.vue'

export default defineComponent({
  components: {
    TmTable,
    TmColoredUl,
    NumbersDropdown,
    TmBadge,
    TmStatus,
    TmFlag,
  },
  setup() {
    const className = (status: any) => {
      return !status || status.name !== 'online' ? 'warning--text font-weight-semi-bold' : ''
    }

    return {
      className,
    }
  },
})
