import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, createTextVNode as _createTextVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_flag = _resolveComponent("tm-flag")!
  const _component_tm_icon = _resolveComponent("tm-icon")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_tm_badge = _resolveComponent("tm-badge")!
  const _component_tm_status = _resolveComponent("tm-status")!
  const _component_tm_colored_ul = _resolveComponent("tm-colored-ul")!
  const _component_numbers_dropdown = _resolveComponent("numbers-dropdown")!
  const _component_tm_table = _resolveComponent("tm-table")!

  return (_openBlock(), _createBlock(_component_tm_table, { "hide-footer": "" }, {
    "body-cell-number-slot": _withCtx((props) => [
      _createVNode(_component_router_link, {
        class: "emphasize--text blue-on-hover font-weight-normal d-flex align-center",
        to: { name: 'base.chats.settings.whatsApp.number' }
      }, {
        default: _withCtx(() => [
          _createVNode(_component_tm_flag, {
            class: "mr-2",
            country: props.row.number
          }, null, 8, ["country"]),
          _createElementVNode("span", {
            class: _normalizeClass(_ctx.className(props.row.status))
          }, _toDisplayString(props.row.number.name), 3),
          (!props.row.status || props.row.status.name !== 'online')
            ? (_openBlock(), _createBlock(_component_tm_icon, {
                key: 0,
                name: "warning",
                class: "warning--text ml-2",
                size: "xLarge"
              }))
            : _createCommentVNode("", true)
        ]),
        _: 2
      }, 1032, ["to"])
    ]),
    "body-cell-title-slot": _withCtx((props) => [
      _createVNode(_component_router_link, {
        class: "emphasize--text blue-on-hover font-weight-normal d-flex align-center",
        to: { name: 'base.chats.settings.whatsApp.number' }
      }, {
        default: _withCtx(() => [
          _createVNode(_component_tm_badge, { static: "" }, {
            default: _withCtx(() => [
              _createElementVNode("span", null, _toDisplayString(props.row.title), 1)
            ]),
            _: 2
          }, 1024)
        ]),
        _: 2
      }, 1032, ["to"])
    ]),
    "body-cell-status-slot": _withCtx((props) => [
      _createVNode(_component_tm_status, _normalizeProps(_guardReactiveProps(props.row.status)), null, 16)
    ]),
    "body-cell-rating-slot": _withCtx((props) => [
      _createVNode(_component_router_link, {
        class: "emphasize--text blue-on-hover font-weight-normal",
        to: { name: 'base.chats.settings.whatsApp.number' }
      }, {
        default: _withCtx(() => [
          _createVNode(_component_tm_colored_ul, {
            color: props.row.rating.color,
            "circle-size": "8px",
            "line-height": "20px"
          }, {
            default: _withCtx(() => [
              _createElementVNode("li", null, _toDisplayString(props.row.rating.text), 1)
            ]),
            _: 2
          }, 1032, ["color"])
        ]),
        _: 2
      }, 1032, ["to"])
    ]),
    "body-cell-limits-slot": _withCtx((props) => [
      _createVNode(_component_router_link, {
        class: "emphasize--text blue-on-hover font-weight-normal",
        to: { name: 'base.chats.settings.whatsApp.number' }
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(props.row.limits), 1)
        ]),
        _: 2
      }, 1032, ["to"])
    ]),
    "body-cell-actions": _withCtx((props) => [
      _createVNode(_component_numbers_dropdown, {
        "number-line": props.row
      }, null, 8, ["number-line"])
    ]),
    _: 1
  }))
}