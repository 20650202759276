
import { defineComponent, ref } from 'vue'
import TmButton from '@/components/shared/TmButton.vue'
import InfoCard from '@/components/shared/templates/InfoCard.vue'
import InfoCardRow from '@/components/shared/templates/InfoCardRow.vue'
import NumbersTable from '@/components/pages/chats/settings/whatsApp/detailed/NumbersTable.vue'
import type { TableHeaders } from '@/definitions/shared/types'
import type { SenderNumberLine } from '@/definitions/chats/settings/whatsApp/types'
import { SenderNumbersMock } from '@/definitions/chats/settings/whatsApp/data'
import { useModals } from '@/compositions/modals'

export default defineComponent({
  components: {
    TmButton,
    InfoCard,
    InfoCardRow,
    NumbersTable,
  },
  setup() {
    const { openModal } = useModals()
    const tableHeaders = ref<TableHeaders[]>([
      { text: 'WhatsApp number', value: 'number-slot' },
      { text: 'Display name', value: 'title-slot' },
      { text: 'Sender status', value: 'status-slot' },
      { text: 'Quality rating', value: 'rating-slot' },
      { text: 'Messaging limits', value: 'limits-slot' },
    ])
    const tableItems = ref<SenderNumberLine[]>(SenderNumbersMock)

    return {
      tableHeaders,
      tableItems,
      openModal,
    }
  },
})
