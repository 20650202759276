
import { defineComponent } from 'vue'
import TmStatus from '@/components/shared/TmStatus.vue'
import InfoCard from '@/components/shared/templates/InfoCard.vue'
import InfoCardRow from '@/components/shared/templates/InfoCardRow.vue'

export default defineComponent({
  components: {
    TmStatus,
    InfoCard,
    InfoCardRow,
  },
})
