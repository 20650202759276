
import type { PropType } from 'vue'
import { defineComponent } from 'vue'
import TmDropdownItem from '@/components/shared/TmDropdownItem.vue'
import TmTableActionButton from '@/components/shared/table/TmTableActionButton.vue'
import TmDropdown from '@/components/shared/TmDropdown.vue'
import { useByoc } from '@/compositions/services/useByoc'
import { useChatsSettings } from '@/compositions/chats/chatsSettings'
import type { SenderNumberLine } from '@/definitions/chats/settings/whatsApp/types'

export default defineComponent({
  components: {
    TmDropdown,
    TmTableActionButton,
    TmDropdownItem,
  },
  props: {
    numberLine: {
      type: Object as PropType<SenderNumberLine>,
      required: true,
    },
  },
  setup(props) {
    const { openUnlinkNumberModal } = useByoc()
    const { openWhatsAppQrCodeModal } = useChatsSettings()
    const viewQr = () => {
      openWhatsAppQrCodeModal(props.numberLine.number.name, props.numberLine.title)
    }
    return {
      openUnlinkNumberModal,
      viewQr,
    }
  },
})
